import styled from 'styled-components'

const Container = styled.div`

  width:100%
 

  ${({ theme }) => theme.mediaQueries.sm} {
    
  }
`

export default Container
