import styled from 'styled-components'
import Container from './Container'

const Page = styled(Container)`
  min-height: calc(100vh - 64px);

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-bottom: 24px;
    width:100%;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-bottom: 32px;
    width:80%;
    margin-left:10%;
  }
`

export default Page
