import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [


 




  {
    pid: 9,
    risk: 5,
    lpSymbol: 'PRV-BUSD LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0xea275c14414de38f5c19c1e3559af032cc1facf4',
    },
    tokenSymbol: 'PRV',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },






  {
    pid: 8,
    risk: 0,
    lpSymbol: 'PRV-BNB LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0xCd18e1151a4831e5EbCEcC611369123012674253',
    },
    tokenSymbol: 'PRV',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },




  {
    pid: 11,
    risk: 5,
    lpSymbol: 'ETH-SUSHI LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x16aFc4F2Ad82986bbE2a4525601F8199AB9c832D',
    },
    tokenSymbol: 'SUSHI',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x947950bcc74888a40ffa2593c5798f11fc9124c4',
    },
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: contracts.eth,
  },
  {
    pid: 12,
    risk: 5,
    lpSymbol: 'XVS-BNB LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x7EB5D86FD78f3852a3e0e064f2842d45a3dB6EA2',
    },
    tokenSymbol: 'XVS',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 13,
    risk: 5,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 14,
    risk: 5,
    lpSymbol: 'BNB-ETH LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  


  {
    pid: 10,
    risk: 5,
    lpSymbol: 'CAKE-BNB LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },



  {
    pid: 15,
    risk: 5,
    lpSymbol: 'USDT-BUSD LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },


  {
    pid: 16,
    risk: 5,
    lpSymbol: 'USDC-BUSD LP',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



 







  {
    pid: 6,
    risk: 3,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '0x11b9a40538544Cc00b31803a34Dd619a2bE6586D',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0xA73D61379627dB94f968C7bb4BD7fF5c07723Ec2',
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
 
  // {
  //   pid: 2,
  //   risk: 4,
  //   lpSymbol: 'CAKE-BNB',
  //   lpAddresses: {
  //     97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
  //     56: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6', // CAKE-BUSD LP
  //   },
  //   tokenSymbol: 'CAKE',
  //   tokenAddresses: {
  //     97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
  //     56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },



  // {
  //   pid: 3,
  //   risk: 1,
  //   lpSymbol: 'ETH-SUSHI',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x17580340F3dAEDAE871a8C21D15911742ec79e0F', 
  //   },
  //   tokenSymbol: 'SUSHI',
  //   tokenAddresses: {
  //     97: '0x4998c1031e1c9cd49fe31f6786a0e6090fda95ea',
  //     56: '0x947950bcc74888a40ffa2593c5798f11fc9124c4',
  //   },
  //   quoteTokenSymbol: QuoteToken.ETH,
  //   quoteTokenAdresses: contracts.eth,
  // },




  // {
  //   pid: 7,
  //   risk: 1,
  //   lpSymbol: 'ETH-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x4D7078a6B348766E7a16cD6e6fCb3064721bc6a6', 
  //   },
  //   tokenSymbol: 'ETH',
  //   tokenAddresses: {
  //     97: '0x4998c1031e1c9cd49fe31f6786a0e6090fda95ea',
  //     56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },


  // {
  //   pid: 4,
  //   risk: 1,
  //   lpSymbol: 'XVS-BNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x41182c32F854dd97bA0e0B1816022e0aCB2fc0bb', 
  //   },
  //   tokenSymbol: 'XVS',
  //   tokenAddresses: {
  //     97: '0x4998c1031e1c9cd49fe31f6786a0e6090fda95ea',
  //     56: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.busd,
  // },





  {
    pid: 5,
    risk: 3,
    lpSymbol: 'PRV',
    isTokenOnly: true,

    lpAddresses: {
      97: '',
      56: '0xea275c14414de38f5c19c1e3559af032cc1facf4', 
    },
    tokenSymbol: 'PRV',
    tokenAddresses: {
      97: '',
      56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },




  {
    pid: 17,
    risk: 5,
    isTokenOnly: true,

    lpSymbol: 'BUSD',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    tokenSymbol: 'BUSD',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



  {
    pid: 18,
    risk: 5,
    isTokenOnly: true,

    lpSymbol: 'USDT',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  


  {
    pid: 19,
    risk: 5,
    isTokenOnly: true,

    lpSymbol: 'ETH',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },







  {
    pid: 20,
    risk: 5,

    lpSymbol: 'ADA-BNB',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
    },
    tokenSymbol: 'ADA',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },







  {
    pid: 21,
    risk: 5,

    lpSymbol: 'BTCB-BUSD',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },






  {
    pid: 22,
    risk: 5,

    lpSymbol: 'BTCB-BNB',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },






  {
    pid: 23,
    risk: 5,

    lpSymbol: 'DOT-BNB',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
    },
    tokenSymbol: 'DOT',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },






  {
    pid: 24,
    risk: 5,

    lpSymbol: 'BUNNY-BNB',
    lpAddresses: {
      97: '0xC72B07Cd3dc7F46B4d2E8E6266f85f8dffd348D0',
      56: '0x5aFEf8567414F29f0f927A0F2787b188624c10E2',
    },
    tokenSymbol: 'BUNNY',
    tokenAddresses: {
      97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },



 
]

export default farms
