export default {
  cake: {
    56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
    97: '0xc66fad408936f74434a37f38a98f722dbda3022c',
  },
  masterChef: {
    56: '0xD8151Da76095264929ab7cB680457c18014D2f9d',
    97: '0xc2e56b8fA5D3f34c3d7c48dF414097F599d4D50C',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xA73D61379627dB94f968C7bb4BD7fF5c07723Ec2',
  },
  lottery: {
    56: '0x938062218d298A5a6B8c44a723A4A7177D2761cA',
    97: '0x78f97C5dF7FC51E68DeA698DB7EF1DA96681f880',
  },
  lotteryNFT: {
    56: '0xDA8B8Fc2aCC81168401F745cBACeC461c2889De3',
    97: '0x8604B06459c3e7200355DcE354921E3012cDD43F',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x4998c1031e1c9cd49fe31f6786a0e6090fda95ea',
  },
  eth:{
    56:"0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    97:""
  }
}
